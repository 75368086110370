import React, { useState,useEffect, useContext } from "react"
import { Button,SpinnerOverlay, Collapsible } from '@components/common';
import Text from '@typo/Text'
import LineText from '@form/LineText'
import Input from '@form/Input'
import MainHeading from '@typo/MainHeading'
import StyledLink from '@typo/StyledLink'
import FlashMessage from '@notification/FlashMessage'
import {FirebaseContext} from '@components/Firebase'
import UTMContext from '@components/UTMContext'
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"
import * as Sentry from '@sentry/gatsby'
import styled from 'styled-components'
import google from "@images/logos/google-icon-80x80.png"
import { useProfileContext } from "@hooks/useProfileContext";


const TermsCheckbox =styled.input`

`

const TermsCheckboxLabel =styled.label`
  font-family:${props => props.theme.mainFontParagraph};
  padding-left:20px;
  
  font-size:0.8rem;
  a {
    font-size:0.8rem;
  }

`
const TermsWrapper = styled.div`
  display:Flex;
  align-items:center;
  padding-top:10px;
  width:90%;
  margin:auto;
`

const CheckButtonWrapper = styled.div`
  margin-top: 15px;
`


const  SocialLoginButton =  styled(Button)`
  //width:48%;
  width:100%;
  background: #ececec;
  color:${props => props.white ? "white": "#606060"};
  border-width: 2px;
  border-color: #ececec;
  &:hover{
    color:white;
    background:#c7c7c7;
    border-color: #c7c7c7;
  }
`
const SocialLoginWrapper = styled.div`
  display:flex;
  justify-content:space-between;
`

const ButtonIcon = styled.img`
  height:1rem;
`
const ButtonContentWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content: center;
`

const ButtonText = styled.p`
  margin:0px;
  padding-left:20px;
  @media ${props => props.theme.phonePort} {
    padding-left:5px;
  }
`

const ContentWrapper = styled.div`
  max-width:450px;
`

const PageWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding:20px;
  justify-content: center;
  min-height:100vh;
  align-items: center;
  @media ${props => props.theme.tabletBelow} {
    height:100%;
  }
`



const Register = (props) => {
  const {firebase, user} = useContext(FirebaseContext);
  const {UTMParameter, setUTMParameter} = useContext(UTMContext);
  const [flashMessage, setFlashMessage] = useState('');
  const { setProfileInit } = useProfileContext()
  const [termsChecked, setTermsChecked]= useState(false);
  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState('');
  const [codeFlashMessages, setCodeFlashMessages] = useState([]);
  const lang = props.pageContext.lang;
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    email:'',
    password:'',
    confirmPassword: '',
    gender: 'Female',
    relationshipStatus:'Single',
    day: '',
    month:'',
    year:''
  });

  let isMounted = true;


function handleSocialLogin(provider){
  if(termsChecked){
    setLoading(true)
    firebase.socialLogin(provider,"register", lang, UTMParameter, setProfileInit)
    .catch(()=>  setLoading(false))
    .then(()=>  setLoading(false));
  } else {
    setFlashMessage({message: t('register.flashMessage-1'),type:"error"});
  }
}


function handleInputChange(e){
  e.persist();
  setFlashMessage(''); // clean error message when inputs change
  setFormValues(currentValues => ( {
    ...currentValues,
    [e.target.name]: e.target.value
  }))
}

function handleTermsChange(e){
  setTermsChecked(e.target.checked) 
}

function checkCoupon(e){
  e.preventDefault();
  const sanatizedCoupon= coupon.replace(/\s/g, '').toLowerCase();
  setLoading(true)
  firebase.checkPartnerCode(sanatizedCoupon).then((res) => {
    console.log(res)
    setLoading(false)
    setCodeFlashMessages(res.data)
    const updatedUtms = {
      ...UTMParameter,
      utm_campaign:sanatizedCoupon,
    }
    setUTMParameter(updatedUtms)

  }).catch((err) => {
    setLoading(false)
    console.log("err",err)
    setCodeFlashMessages([{message: err.message, type:"error"}])
  })
}


function handleSubmit(e){
  e.preventDefault();
  if(formValues.password === formValues.confirmPassword){
    setLoading(true)
    firebase.register({
      email: formValues.email,
      password: formValues.password,
      lang,
      UTMs: UTMParameter,
      setProfileInit
    })
    
    .catch(error => {
      if(isMounted){
        Sentry.captureMessage(error);
        setFlashMessage({"message": error.message,"type":"error"});
      }
    }).then(()=> setLoading(false));
  }else if(formValues.password !== formValues.confirmPassword){
    setFlashMessage({"message": t('register.flashMessage-2'),"type":"error"});
  };
}


  return (
  <PageWrapper>
    <Header location={props.location} /> 
    <SpinnerOverlay show={loading}/>
    <ContentWrapper>
      <form onSubmit={handleSubmit} marginTopBot>
        <MainHeading center>{t('register.sectionTitle')}</MainHeading>
        <Text center>{t('register.sectionSubtitle')}</Text>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.email} placeholder="email" type="email" required name="email" autocomplete="on"/>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.password} placeholder="password" type="password" required minLength={6} name="password" autocomplete="on"/>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.confirmPassword} placeholder="confirm password" type="password" required minLength={6} name="confirmPassword" autocomplete="on"/>
          <TermsWrapper>
            <TermsCheckbox onChange={handleTermsChange} type="checkbox" required name="terms" value ={formValues.terms}/>
            <TermsCheckboxLabel id="terms" name="terms">{t('register.agreeTerms-1')}<StyledLink to={t('urls.terms-of-service')}>{t('register.agreeTerms-2')}</StyledLink></TermsCheckboxLabel>
          </TermsWrapper>
          {flashMessage && <FlashMessage message={flashMessage}/>}
          <Button type ="submit" block marginTop>{t('register.submitButton')}</Button>
          <LineText>{t('register.lineText')}</LineText>
          <SocialLoginWrapper>
        <SocialLoginButton type="button" inverted border  onClick={() => handleSocialLogin("Google")}>
          <ButtonContentWrapper>
          <ButtonIcon src={google}/>
          <ButtonText  >Google</ButtonText>
          </ButtonContentWrapper>
        </SocialLoginButton>
        {/* <SocialLoginButton type="button" inverted border   onClick={() => handleSocialLogin("Facebook")}>
          <ButtonContentWrapper>
          <ButtonIcon src={facebook}/>
          <ButtonText >Facebook</ButtonText>
          </ButtonContentWrapper>
        </SocialLoginButton> */}
        </SocialLoginWrapper>
      </form>
      { lang === "en" && 
        <Collapsible question="PARTNER CODE?">
          <form  onSubmit={(e) => checkCoupon(e)}>
              <Text>Do you have a code from one of our great partners? Enter it here to redeem your gift.</Text>
              <Input  
                value ={coupon} 
                name="coupon" 
                placeholder="CODE"  
                type="text" 
                onChange = {e => {
                  e.persist();
                  setFlashMessage(false);
                  setCoupon(e.target.value)
                }
              }/>
              <CheckButtonWrapper>
                <Button 
                  block
                  type ="submit"
                >
                  REDEEM
                </Button>
              </CheckButtonWrapper>
              {codeFlashMessages.map((message) => (
                <FlashMessage message={{
                  message:t(`promotion-feedback.${message.type}`,{promoCode:message.code, duration: message?.duration, storyTitle: message?.storyTitles?.[lang]}),
                  type:message.status}}
                />
              ))}
          </form>
        </Collapsible>
      }
    </ContentWrapper>
  </PageWrapper>
  )
}
export default Register